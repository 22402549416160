@import "../../Global.scss";

.project-item {

  margin: 10px;
  //filter: drop-shadow(20px 10px 5px rgba(31, 31, 31, 0.6));
  border: solid 2px;
  border-color: $primary-color $secondary-color $secondary-color $primary-color;
  .preojct-item-opacity-layer {
    display: flex;
    .project-item-image {
      width: 360px;
      height: 360px;
    }

    .project-item-info {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($secondary-background-color, 0.8);
      display: none;

      .project-item-info-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        width: 100%;
        .project-item-info-title {
          color: white;
          text-align: center;
          font-size: 1rem;
        }
        .project-item-info-description {
          color: white;
          text-align: center;
          font-size: 0.8rem;
        }
      }
    }

    &:hover {
      position: relative;
      overflow: hidden;
     
      .project-item-info {
        display: block;
      }
    }
  }
}
