@import "../../styles/Global.scss";

.nav-bar {
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 80px;
  z-index: 10;
  background-color: rgba($primary-background-color, 0.8);
  border-bottom: 2px solid $primary-color;
  .nav-logo-image {
    width: 150px;
    height: 100%;
  }

  .menu-icon {
    display: none;
    width: 30px;
    height: 30px;
  }

  .nav-menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
    height: 60px;
    gap: 8%;
    .nav-link-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      .nav-link {
        text-decoration: none;
        color: $secondary-color;
        font-size: 16px;
        height: 30px;
        &:hover {
          color: $primary-color;
          padding-bottom: 2px;
          border-bottom: 2px solid $primary-color;
        }
      }
      .nav-link-selected {
        text-decoration: none;
        color: $secondary-color;
        font-size: 16px;
        height: 30px;
        padding-bottom: 2px;
        border-bottom: 2px solid $primary-color;
        &:hover {
          color: $primary-color;
          padding-bottom: 2px;
          border-bottom: 2px solid $primary-color;
        }
      }
    }
  }
  .change-language {
    text-decoration: none;
    color: $secondary-color;
    font-size: 30px;
    height: 40px;
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  .nav-bar {
    background-color: $primary-background-color;
    justify-content: space-between;
    padding: 0 3%;
    z-index: 999;
    .menu-icon {
      display: flex;
      width: 30px;
      height: 30px;
    }

    .nav-menu {
      flex-direction: column;
      position: absolute;
      top: -300px;
      left: 0;
      width: 100%;
      height: 300px;
      gap: 0;
      opacity: 0;
      background-color: $primary-background-color;

      .nav-link-container {
        height: 100%;
        width: 100%;
        &:hover {
          background-color: $secondary-background-color;
          border-radius: 0;
        }
        .nav-link {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          text-align: center;
          transition: all 0.3s ease;
          &:hover {
            border-bottom: 3px solid $primary-color;
            transition: all 0.3s ease;
          }
        }
        .nav-link-selected {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          text-align: center;
          border-bottom: 3px solid $primary-color;
          transition: all 0.3s ease;
          &:hover {
            border-bottom: 3px solid $primary-color;
            transition: all 0.3s ease;
          }
        }
      }
    }
    .change-language {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      text-align: center;
      transition: all 0.3s ease;
      &:hover {
        background-color: $secondary-background-color;
        border-radius: 0;
      }
    }
    .nav-menu.active {
      top: 80px;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;

      .nav-link {
      }
    }
  }
}
