@import "../../Global.scss";

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px;
  width: 250px;
  height: 300px;
  border: solid 2px;
  border-color: $primary-color $secondary-color $secondary-color $primary-color;
  white-space: nowrap;
  background: linear-gradient(
    to right bottom,
    $primary-background-color,
    $secondary-background-color
  );
  filter: drop-shadow(20px 10px 5px rgba(31, 31, 31, 0.6));
  .card-screw-a,
  .card-screw-b {
    position: relative;
    width: 16px;
    height: 16px;
    animation: screw 2s linear 0s infinite reverse;
  }
  .card-screw-a {
    bottom: 40px;
    right: 15px;
    align-self: flex-end;
  }
  .card-screw-b {
    top: 40px;
    left: 15px;
    align-self: flex-start;
  }
  .card-image {
    width: 50px;
    height: 50px;
    margin: 0px 0;
  }
  .card-title {
    font-size: 18px;
    text-align: center;
    color: $primary-color;
    margin: 20px 0;
  }

  .card-button {
    width: 100px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border: solid 1px $primary-color;
    color: $primary-color;
    transition: all 0.3s ease;
    &:hover,
    &:active {
      width: 180px;
      border: solid 1px $secondary-color;
      color: $secondary-color;
      background-color: $primary-color;
      transition: all 0.3s ease;
    }
  }
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 480px) {
}

@keyframes screw {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
