@import "../../Global.scss";

.about-container {
  width: 100%;
  color: $secondary-color;

  .section-a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 600px;
    .section-a-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: left;
      padding-top: 250px;
      width: 40%;
      letter-spacing: 5px;
      &::after {
        color: $primary-color;
        padding-left: 50px;
        letter-spacing: 0px;
        content: "━━━━━━━";
      }
    }

    .section-a-right {
      padding-top: 100px;
      display: flex;
      flex-direction: column;
      width: 50%;
      .section-a-title {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        font-size: 36px;
        letter-spacing: 5px;
        &::before {
          color: $primary-color;
          padding-right: 10px;
          letter-spacing: 0px;
          content: "━";
        }
      }
      .section-a-description {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: justify;
        padding-top: 30px;
        font-size: 16px;
        &::after {
          color: $primary-color;
          letter-spacing: 0px;
          content: "━━━━━";
        }
      }
    }
  }
  .section-b {
    display: flex;
    justify-content: center;
    background-color: $primary-color;
    .section-b-image {
      padding: 2px 0;
      width: 100%;
    }
  }

  .section-c {
    height: 700px;
    background-color: $secondary-background-color;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .section-c-left {
      display: flex;
      justify-content: center;
      width: 45%;
      .section-c-image-container-l {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        margin-right: 20px;
      }
      .section-c-image-container-r {
        display: flex;
        flex-direction: column;
      }
      .section-c-image {
        border: solid 2px;
        border-color: $primary-color $secondary-color $secondary-color $primary-color;
        margin-top: 20px;
        width: 160px;
        height: 200px;
      }
    }
    .section-c-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 45%;
      .section-c-since-text {
        color: $primary-color;
        font-size: 16px;
      }
      .section-c-title {
        display: flex;
        flex-direction: column;
        font-size: 20px;
        letter-spacing: 3px;
        &::after {
          color: $primary-color;
          letter-spacing: 0px;
          content: "━━━━";
        }
      }
      .section-c-description {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: justify;
        padding-top: 30px;
        &::after {
          color: $primary-color;
          letter-spacing: 0px;
          content: "━━━━━";
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .about-container {
    .section-a {
      height: 550px;
      .section-a-title {
        font-size: 24px;
      }
      .section-a-left {
        width: 35%;
      }
      .section-a-right {
        width: 55%;
        justify-content: center;
        padding-top: 0px;
        .section-a-description {
          font-size: 12px;
        }
      }
    }
    .section-c {
      height: 850px;
      flex-direction: column-reverse;
      .section-c-left {
        width: 50%;
        flex-direction: row;
        align-items: center;
        .section-c-image-container-l {
          flex-direction: column;
          margin: 00px 0px 0px 0px;
        }
        .section-c-image-container-r {
          flex-direction: column;
          margin: 40px 0px 0px 0px;
        }
        .section-c-image {
          margin: 10px 5px;
          width: 160px;
          height: 200px;
        }
      }
      .section-c-right {
        width: 90%;
        .section-c-description {
          font-size: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .about-container {
    width: 100%;
    color: $secondary-color;
    .section-a {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      .section-a-title {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        font-size: 24px;
        &::before {
          color: $primary-color;
          padding-right: 20px;
          letter-spacing: 0px;
          content: "━━";
        }
      }

      .section-a-left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        padding-top: 0px;
        width: 90%;
      }
      .section-a-right {
        padding-top: 10px;
        width: 90%;
      }
    }
    .section-c {
      height: 1000px;
      flex-direction: column-reverse;
      .section-c-left {
        display: flex;
        justify-content: center;
        flex-direction: row;
        width: 90%;
        .section-c-image-container-l {
          display: flex;
          flex-direction: column;
          margin: 0 5px;
        }
        .section-c-image-container-r {
          display: flex;
          flex-direction: column;
          margin: 30px 5px 0 5px;
        }
        .section-c-image {
          margin-top: 20px;
          width: 160px;
          height: 200px;
        }
      }
      .section-c-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 90%;
        .section-c-since-text {
          color: $primary-color;
          font-size: 16px;
        }
        .section-c-title {
          display: flex;
          flex-direction: column;
          font-size: 24px;
          font-weight: 700;
          letter-spacing: 3px;
          &::after {
            color: $primary-color;
            letter-spacing: 0px;
            content: "━━━━";
          }
        }
        .section-c-description {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          text-align: justify;
          padding-top: 30px;
          &::after {
            color: $primary-color;
            letter-spacing: 0px;
            content: "━━━━━";
          }
        }
      }
    }
  }
}
