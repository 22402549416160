@import "../../Global.scss";
.contact-container {
  display: flex;
  justify-content: center;
  width: 100%;

  margin: 20px 0;
  .contact-detail-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 45%;
    color: $secondary-color;
    margin: 30px 0;
    .contact-text {
      font-size: 24px;
    }
    .contact-sub {
      font-size: 16px;
      padding-bottom: 30px;
    }
    .contact-phone {
      padding-bottom: 5px;
    }
    .contact-email {
   
    }
  }
  .contact-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
  }
}

@media screen and (max-width: 768px) {
  .contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .contact-detail-container {
      width: 100%;
    }

    .contact-form-container {
      width: 100%;
    }
  }
}
