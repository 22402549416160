@import "../../Global.scss";

.form-container {
  padding: 0 50px;
  height: 550px;
  border: solid 2px;
  border-color: $primary-color $secondary-color $secondary-color $primary-color;
  display: flex;
  background-color: $secondary-background-color;
  // justify-content: center;
  align-items: center;
  flex-direction: column;
  filter: drop-shadow(20px 10px 5px rgba(31, 31, 31, 0.6));

  .form-title {
    padding: 20px 0;
    font-size: 24px;
    color: $secondary-color;
    text-align: center;
  }

  .form-input-container {
    .form-label {
      display: flex;
      padding: 10px 0 4px 0;
      color: $secondary-color;
    }
    .form-input-error-container {
      height: 15px;
      .form-input {
        width: 250px;
        font-size: 16px;
        color: $secondary-color;
        border-bottom: 2px solid $primary-color;
      }

      .form-error-message {
        color: red;
        font-size: 12px;
      }
    }
    .form-input-error-textarea-container {
      height: 150px;
      .form-textarea {
        width: 250px;
        height: 120px;
        font-size: 16px;
        color: $secondary-color;
        border: 2px solid $primary-color;
      }
      .form-error-message {
        color: red;
        font-size: 12px;
      }
    }
  }
}
.form-button {
  width: 200px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border: solid 1px $primary-color;
  color: $primary-color;
  transition: all 0.3s ease;
  cursor: pointer;
  &:hover,
  &:active {
    border: solid 1px $secondary-color;
    color: $secondary-color;
    background-color: $primary-color;
    transition: all 0.3s ease;
  }
}

@media screen and (max-width: 480px) {
  .form-container {
    width: 320px;
    .form-title {
      font-size: 20px;
    }
    .form-input-container {
      .form-input-error-container {
        .form-input {
          width: 200px;
        }
      }
      .form-input-error-textarea-container {
        .form-textarea {
          width: 200px;
        }
      }
    }
  }
}
