@import "Global.scss";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Outfit", sans-serif;
  font-weight: 400;
}

body {
  background: $primary-background-color;
}

a {
  color: inherit;
  text-decoration: none;
}

li {
  list-style: none;
}

.App {
  min-height: 100vh;
}

.app-container {
  display: flex;
  justify-content: center;
  padding-top: 80px;
  z-index: 10;
}

input,
textarea {
  all: unset;
}

input {
  &:is(:-webkit-autofill, :-webkit-autofill:focus) {
    transition: background-color 6000000s 0s, color 6000000s 0s;
  }
}
