$primary-color: #F68C43;
$secondary-color:#EAECEE;
$primary-background-color: #1D2228;
$secondary-background-color: #2c343e;

.main-color{
  color: $primary-color;
}
.second-color{
  color: $secondary-color;
}