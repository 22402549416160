.sketch-container {
  position: absolute;
  z-index: -1;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  opacity: 0.5;
  filter: blur(2px);
}
