@import "../../Global.scss";

.category {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  .category-number {
    font-size: 16px;
    color: $primary-color;
    align-self: start;

    &::after {
      padding-left: 20px;
      content: "━━━━";
    }
  }
  .category-title {
    font-size: 20px;
    color: $secondary-color;
    text-align: end;


    &::after {
      content: "━━━━";
    }
    .category-description {
      text-align: end;
      font-size: 16px;
      padding-top: 20px;
    }
  }
}

@media screen and (max-width: 768px) {
  .category {
    width: 230px;
    .category-number {
    }
    .category-title {
      font-size: 20px;
      .category-description{
        font-size: 12px;
      }
    }
 
  }
}

@media screen and (max-width: 480px) {
  .category {
    width: 150px;
    .category-number {
    }
    .category-title {
      font-size: 20px;
    }
  }
}
