@import "../../Global.scss";

.projects-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  .section-a {
    display: flex;
    border-bottom: 5px solid $primary-color;
    height: 200px;
    .projects-title {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40%;
      line-height: 100px;
      font-size: 36px;
      letter-spacing: 5px;
      color: $secondary-color;
    }
    .projects-description {
      display: flex;
      align-items: center;
      text-align: justify;
      color: $secondary-color;
      width: 50%;
      font-size: 16px;
    }
  }
  .project-items-margin {
    display: flex;
    flex-direction: column;
    margin: 40px 0 0 0;
  }
  .projects-items-container {
    background-color: $secondary-background-color;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px 0;
  }
}

@media screen and (max-width: 768px) {
  .projects-container {
    .section-a {
      flex-direction: column;
      .projects-title {
        font-size: 24px;
        width: 100%;
        line-height: 100px;
      }
      .projects-description {
        font-size: 12px;
        padding: 0 40px;
        width: 100%;
      }
    }
    .project-items-margin {
      margin: 20px 0;
      &::after {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .projects-container {
    .section-a {
      flex-direction: column;
      .projects-title {
        font-size: 24px;
        width: 100%;
        line-height: 80px;
      }
      .projects-description {
        font-size: 12px;
        padding: 0 40px;
        width: 100%;
      }
    }
    .project-items-margin {
      margin: 20px 0;
      &::after {
        display: none;
      }
    }
  }
}
