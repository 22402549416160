@import "../../Global.scss";

.warning-tape {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: $primary-color;
  height: 120px;
  border-top: solid 10px;
  border-bottom: solid 10px;
  background: repeating-linear-gradient(
    45deg,
    $secondary-background-color,
    $secondary-background-color 40px,
    $primary-color 40px,
    $primary-color 80px
  );
  .warning-tape-text {
    border: solid 5px;
    height: auto;
    padding: 0 30px;
    background-color: $secondary-background-color;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 10px;
    white-space: nowrap;
  }
}

@media screen and (max-width: 768px) {
  .warning-tape {
    height: 80px;
    border-top: solid 7px;
    border-bottom: solid 7px;
    background: repeating-linear-gradient(
      45deg,
      $secondary-background-color,
      $secondary-background-color 30px,
      $primary-color 30px,
      $primary-color 60px
    );
    .warning-tape-text {
      padding: 0 10px;
      font-size: 24px;
      letter-spacing: 6px;
      background-color: none;
    }
  }
}

@media screen and (max-width: 480px) {
  .warning-tape {
    height: 50px;
    border-top: solid 4px;
    border-bottom: solid 4px;
    background: repeating-linear-gradient(
      45deg,
      $secondary-background-color,
      $secondary-background-color 20px,
      $primary-color 20px,
      $primary-color 40px
    );
    .warning-tape-text {
      padding: 0 5;
      font-size: 16px;
      letter-spacing: 5px;
      background-color: none;
    }
  }
}
