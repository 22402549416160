@import "../../styles/Global.scss";

.footage-container {
  border-top: 2px solid $primary-color;
  background: $primary-background-color;
  height: 80px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .footage-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 15px 0;
    color: $secondary-color;
    .footage-icon {
      height: 20px;
      width: 20px;
      margin: 0 15px;

    }
  }

  .copyright {
    color: $secondary-color;
    text-align: center;
    font-size: 12px;
  }
}
