@import "../../Global.scss";

.home-container {
  width: 100%;
  .section-a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: auto;  
    padding: 100px 5%;
    color: $secondary-color;
    height: 600px;
    .section-a-text {
      font-size: 24px;
    }
    .section-a-title {
      color: $primary-color;
      font-size: 36px;
      font-weight: 700;
      letter-spacing: 10px;
    }
    .section-a-sub-title {
      font-size: 24px;
      padding-bottom: 30px;
    }
    .section-a-description {
      font-size: 16px;
    }
  }
  .section-b {
    //padding: 30px 0;
  }
  .section-c {
    padding: 40px 0 40px 0;
    background-color: $secondary-background-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    .section-c-title {
      font-size: 24px;
      text-align: center;
      color: $secondary-color;
      letter-spacing: 3px;
      text-shadow: 3px 3px 3px rgba(31, 31, 31, 1);
      &::before {
        color: $primary-color;
        padding-right: 30px;
        content: "━";
      }
      &::after {
        color: $primary-color;
        padding-left: 30px;
        content: "━";
      }
    }
    .section-c-grid {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding: 20px 40px;
    }
  }
  .section-d {
    text-align: center;
    background-color: $primary-background-color;
    padding: 30px 0;
    .section-d-sub-title {
      font-size: 16px;
      color: $primary-color;
      padding-bottom: 5px;
    }
    .section-d-title {
      font-size: 24px;
      text-align: center;
      color: $secondary-color;
      letter-spacing: 3px;
      text-shadow: 3px 3px 3px rgba(31, 31, 31, 1);
      &::before {
        color: $primary-color;
        padding-right: 30px;
        content: "━";
      }
      &::after {
        color: $primary-color;
        padding-left: 30px;
        content: "━";
      }
    }

    .section-d-category-container {
      // display: flex;
      // justify-content: space-between;
      // padding: 0 300px;
      padding: 30px 0 10px 0;
      gap: 2% 0;
      display: grid;
      grid-template-columns: 250px 250px 250px;
      justify-content: space-evenly;
    }
  }
}

@media screen and (max-width: 768px) {
  .home-container {
    .section-a{
      padding: 50px 5%;
    }
    .section-b {
      //padding: 10px 0;
    }
    .section-c {
      .section-c-grid {
        width: 100%;
      }
    }
    .section-d {
      .section-d-category-container {
        grid-template-columns: 230px 230px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .home-container {
    .section-a{
  
    }
    .section-c {
      
      .section-c-grid {

      }
    }
    .section-d {
      .section-d-category-container {
        grid-template-columns: 150px 150px;
      }
    }
  }
}
