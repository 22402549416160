@import "../../Global.scss";
.project-details-container {
  display: flex;

  align-items: center;
  width: 100%;

  color: $secondary-color;
  background-color: $secondary-background-color;
  .project-details-image-container {
    display: flex;

    flex-direction: column;
    overflow-y: scroll;
    width: 100%;
    height: 600px;
    .project-details-image {
    }
  }
  .project-details-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px 10%;
    .project-details-left-container {
      width: 100%;
      &::before {
        color: $primary-color;
        letter-spacing: 0px;
        content: "━━━━━━";
      }
      .project-details-title {
        display: flex;
        flex-direction: column;
        font-size: 24px;
      }
      .project-details-sub{
        font-size: 16px;
      }
      .project-details-year {
        font-size: 16px;
      }
      .project-details-categories li {
        list-style: square;
        margin-left: 20px;
        text-transform: capitalize;
      }
    }
    .project-details-line {
      width: 100%;
      margin: 20px 0;
      border: 1px solid $primary-color;
    }

    .project-details-right-container {
      width: 100%;

      &::after {
        color: $primary-color;
        letter-spacing: 0px;
        content: "━━━━━━";
      }
      .project-details-achievements li {
        list-style: square;
        margin-left: 20px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .project-details-container {
    flex-direction: column-reverse;
    .project-details-image-container {
      height: auto;
    }
  }
}
